<template>
    <div class="portfolio" dir="rtl">
        <h1>محفظتنا</h1>

        <section class="featured-projects">
            <h2>المشاريع المميزة</h2>
            <div class="project-grid">
                <div class="project-item">
                    <h3>التعلم الآلي لتوقع الطلب في البيع بالتجزئة</h3>
                    <p>تم تطوير نموذج تنبؤي لسلسلة بيع بالتجزئة رائدة لتوقع الطلب وتحسين مستويات المخزون، مما أدى إلى تقليل الفائض بنسبة 20٪ وزيادة المبيعات بنسبة 15٪.</p>
                    <img :src="require('@/assets/retail-forecasting.jpg')" alt="لوحة تحكم التعلم الآلي" class="project-image">
                    <router-link to="/case-studies/retail-forecasting" class="btn btn-outline">اقرأ دراسة الحالة</router-link>
                </div>
                <div class="project-item">
                    <h3>حل CRM مخصص لشركة ناشئة في مجال التكنولوجيا</h3>
                    <p>تم تصميم ونشر نظام CRM مخصص يتكامل بسلاسة مع سير العمل الحالي، مما يعزز تفاعل العملاء وكفاءة العمليات بنسبة 30٪.</p>
                    <img :src="require('@/assets/crm-interface.jpg')" alt="واجهة CRM" class="project-image">
                    <router-link to="/case-studies/crm-solution" class="btn btn-outline">عرض تفاصيل المشروع</router-link>
                </div>
                <div class="project-item">
                    <h3>أداة تحليل وسائل التواصل الاجتماعي المدعومة بالذكاء الاصطناعي</h3>
                    <p>تم إنشاء أداة شاملة لتحليل اتجاهات وسائل التواصل الاجتماعي والمشاعر، مما يساعد شركة التسويق على الحصول على رؤى قابلة للتنفيذ وتعزيز حملات العملاء.</p>
                    <img :src="require('@/assets/social-media-analytics.jpg')" alt="تحليل وسائل التواصل الاجتماعي" class="project-image">
                    <router-link to="/case-studies/social-media-analytics" class="btn btn-outline">استكشاف التكنولوجيا</router-link>
                </div>
            </div>
        </section>

        <section class="client-testimonials">
            <h2>شهادات العملاء</h2>
            <div class="testimonial-grid">
                <div class="testimonial-item">
                    <blockquote>
                        "خبرة الفريق في الذكاء الاصطناعي والتعلم الآلي حولت إدارة المخزون لدينا، مما أدى إلى توفير كبير في التكاليف وتحسين استجابة سلسلة التوريد لدينا."
                    </blockquote>
                    <cite>جون دو، الرئيس التنفيذي لشركة RetailWorld</cite>
                    <img :src="require('@/assets/retailworld-logo.png')" alt="شعار RetailWorld" class="client-logo">
                </div>
                <div class="testimonial-item">
                    <blockquote>
                        "بفضل حل CRM المخصص الخاص بهم، نحن الآن قادرون على إدارة تفاعلات العملاء بشكل أكثر فعالية من أي وقت مضى، مما أدى إلى زيادة كبيرة في رضا العملاء."
                    </blockquote>
                    <cite>جين سميث، مؤسس Startech</cite>
                    <img src="https://via.placeholder.com/100x50" alt="شعار Startech" class="client-logo">
                </div>
                <div class="testimonial-item">
                    <blockquote>
                        "أدواتهم المدعومة بالذكاء الاصطناعي أعطتنا ميزة تنافسية في فهم اتجاهات السوق وسلوك المستهلك. الرؤى المقدمة لا تقدر بثمن."
                    </blockquote>
                    <cite>أليكس براون، مدير التسويق في BrandMasters</cite>
                    <img src="https://via.placeholder.com/100x50" alt="شعار BrandMasters" class="client-logo">
                </div>
            </div>
        </section>

        <section class="cta">
            <h2>هل أنت مستعد لرفع مستوى عملك بحلولنا؟</h2>
            <router-link to="/contact" class="btn btn-primary">اتصل بنا اليوم</router-link>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Portfolio'
}
</script>

<style scoped>
.portfolio {
    padding: 2rem;
}

h1,
h2 {
    text-align: center;
    margin-bottom: 2rem;
}

.featured-projects,
.client-testimonials,
.cta {
    margin-bottom: 4rem;
}

.project-grid,
.testimonial-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
}

.project-item,
.testimonial-item {
    background-color: #f8f9fa;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.project-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
}

.client-logo {
    max-width: 100px;
    height: auto;
    margin-top: 1rem;
}

.btn {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    border-radius: 0.25rem;
    text-decoration: none;
    font-weight: bold;
    transition: all 0.3s ease;
}

.btn-outline {
    border: 2px solid #007bff;
    color: #007bff;
}

.btn-outline:hover {
    background-color: #007bff;
    color: white;
}

.btn-primary {
    background-color: #007bff;
    color: white;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.cta {
    text-align: center;
    background-color: #f8f9fa;
    padding: 3rem;
    border-radius: 0.5rem;
}

blockquote {
    font-style: italic;
    margin-bottom: 1rem;
}

cite {
    display: block;
    font-style: normal;
    margin-top: 0.5rem;
}
</style>