<template>
    <div class="services" dir="rtl">
        <h1>خدماتنا</h1>
        <p class="intro">نحن متخصصون في حلول تطوير الويب والتطبيقات المصممة خصيصًا للمنطقة العربية، ونقدم مجموعة من الخدمات لتلبية احتياجاتك الرقمية.</p>

        <div class="service-list">
            <div class="service-item">
                <font-awesome-icon :icon="['fas', 'laptop-code']" class="service-icon" aria-label="تطوير الويب" />
                <div class="service-content">
                    <h2>تطوير الويب</h2>
                    <p>مواقع ويب مخصصة ومتجاوبة محسنة للغتين العربية والإنجليزية، تضمن تجربة مستخدم سلسة عبر جميع الأجهزة.</p>
                </div>
            </div>

            <div class="service-item">
                <i class="fas fa-mobile-alt service-icon" aria-label="تطوير تطبيقات الجوال"></i>
                <div class="service-content">
                    <h2>تطوير تطبيقات الجوال</h2>
                    <p>تطبيقات جوال أصلية ومتعددة المنصات مصممة لتلبية الاحتياجات الفريدة للسوق العربية، مع دعم لواجهة المستخدم العربية.</p>
                </div>
            </div>

            <div class="service-item">
                <i class="fas fa-shopping-cart service-icon" aria-label="حلول التجارة الإلكترونية"></i>
                <div class="service-content">
                    <h2>حلول التجارة الإلكترونية</h2>
                    <p>متاجر إلكترونية قوية مع بوابات دفع متكاملة شائعة في العالم العربي، ودعم متعدد اللغات، وميزات خاصة بالمنطقة.</p>
                </div>
            </div>

            <div class="service-item">
                <i class="fas fa-users service-icon" aria-label="استشارات تكنولوجيا المعلومات"></i>
                <div class="service-content">
                    <h2>استشارات تكنولوجيا المعلومات</h2>
                    <p>نصائح خبيرة حول التحول الرقمي، واختيار التقنيات المناسبة، واستراتيجية تكنولوجيا المعلومات المصممة للشركات العاملة في المنطقة العربية.</p>
                </div>
            </div>

            <div class="service-item">
                <i class="fas fa-tasks service-icon" aria-label="إدارة المشاريع"></i>
                <div class="service-content">
                    <h2>إدارة المشاريع</h2>
                    <p>إشراف فعال على مشاريعك الرقمية من البداية إلى الإطلاق، مع ضمان التسليم في الوقت المحدد والالتزام بمعايير الامتثال الإقليمية.</p>
                </div>
            </div>

            <div class="service-item">
                <i class="fas fa-bolt service-icon" aria-label="مشاريع سريعة التنفيذ"></i>
                <div class="service-content">
                    <h2>مشاريع سريعة التنفيذ</h2>
                    <p>تطوير وإطلاق سريع للمنتجات الأولية القابلة للتطبيق، وصفحات الهبوط، والتطبيقات صغيرة الحجم لتلبية احتياجات العمل العاجلة.</p>
                </div>
            </div>

            <div class="service-item">
                <i class="fas fa-search-plus service-icon" aria-label="تحسين محركات البحث والتسويق الرقمي باللغة العربية"></i>
                <div class="service-content">
                    <h2>تحسين محركات البحث والتسويق الرقمي باللغة العربية</h2>
                    <p>تحسين وجودك الرقمي لمحركات البحث العربية وتنفيذ استراتيجيات التسويق الرقمي الخاصة بالمنطقة.</p>
                </div>
            </div>

            <div class="service-item">
                <i class="fas fa-pencil-ruler service-icon" aria-label="تصميم واجهة المستخدم وتجربة المستخدم للواجهات العربية"></i>
                <div class="service-content">
                    <h2>تصميم واجهة المستخدم وتجربة المستخدم للواجهات العربية</h2>
                    <p>إنشاء واجهات مستخدم بديهية ومناسبة ثقافيًا، مع التركيز على الطباعة العربية والتخطيط وتجربة المستخدم.</p>
                </div>
            </div>
        </div>

        <div class="cta-section">
            <h2>هل أنت مستعد لرفع مستوى وجودك الرقمي في السوق العربية؟</h2>
            <router-link to="/contact" class="cta-button">تواصل معنا</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>

<style scoped>
.services {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Arial', sans-serif;
}

h1 {
    font-size: 3rem;
    color: #2c3e50;
    margin-bottom: 1.5rem;
    text-align: center;
}

.intro {
    font-size: 1.2rem;
    color: #34495e;
    margin-bottom: 3rem;
    text-align: center;
    line-height: 1.6;
}

.service-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
}

.service-item {
    background-color: #f8f9fa;
    border-radius: 15px;
    padding: 2rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.service-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.service-icon {
    font-size: 3rem;
    color: #3498db;
    margin-bottom: 1.5rem;
    transition: color 0.3s ease;
}

.service-item:hover .service-icon {
    color: #2980b9;
}

.service-content h2 {
    font-size: 1.5rem;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.service-content p {
    color: #34495e;
    font-size: 1rem;
    line-height: 1.6;
}

.cta-section {
    margin-top: 4rem;
    text-align: center;
    background-color: #3498db;
    padding: 3rem;
    border-radius: 15px;
    color: white;
}

.cta-section h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
}

.cta-button {
    display: inline-block;
    background-color: white;
    color: #3498db;
    padding: 1rem 2rem;
    border-radius: 30px;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.1rem;
    transition: all 0.3s ease;
}

.cta-button:hover {
    background-color: #2c3e50;
    color: white;
    transform: scale(1.05);
}
</style>