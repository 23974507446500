<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <rect width="64" height="64" fill="#ddd" />
        <text x="32" y="32" font-family="Arial" font-size="12" fill="#333" text-anchor="middle" dy=".3em">SEO</text>
    </svg>
</template>

<script>
export default {
    name: 'SeoIcon'
}
</script>