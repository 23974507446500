<template>
    <div class="contact">
        <h1>تواصل معنا</h1>
        <div class="contact-content">
            <div class="contact-info">
                <h2>معلومات الاتصال</h2>
                <p><i class="fas fa-map-marker-alt"></i> العنوان: برنو، فيلتريزني 9، جمهورية التشيك</p>
                <p><i class="fas fa-phone"></i> الهاتف: +420 732 580 600</p>
                <p><i class="fas fa-envelope"></i> البريد الإلكتروني: info@webna.co</p>
                <div class="social-media">
                    <a href="#" aria-label="فيسبوك"><i class="fab fa-facebook"></i></a>
                    <a href="#" aria-label="تويتر"><i class="fab fa-twitter"></i></a>
                    <a href="#" aria-label="لينكد إن"><i class="fab fa-linkedin"></i></a>
                </div>
            </div>
            <form class="contact-form" @submit.prevent="submitForm">
                <h2>أرسل لنا رسالة</h2>
                <div class="form-group">
                    <label for="name">الاسم</label>
                    <input type="text" id="name" v-model="form.name" required>
                </div>
                <div class="form-group">
                    <label for="email">البريد الإلكتروني</label>
                    <input type="email" id="email" v-model="form.email" required>
                </div>
                <div class="form-group">
                    <label for="subject">الموضوع</label>
                    <input type="text" id="subject" v-model="form.subject" required>
                </div>
                <div class="form-group">
                    <label for="message">الرسالة</label>
                    <textarea id="message" v-model="form.message" required></textarea>
                </div>
                <button type="submit" class="submit-btn">إرسال</button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Contact',
    data() {
        return {
            form: {
                name: '',
                email: '',
                subject: '',
                message: ''
            }
        }
    },
    methods: {
        submitForm() {
            // Here you would typically send the form data to your backend
            console.log('Form submitted:', this.form);
            // Reset form after submission
            this.form = { name: '', email: '', subject: '', message: '' };
            // You might want to show a success message to the user here
        }
    }
}
</script>

<style scoped>
.contact {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Arial', sans-serif;
    direction: rtl;
}

h1 {
    font-size: 2.5rem;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 2rem;
}

.contact-content {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.contact-info, .contact-form {
    flex: 1;
    min-width: 300px;
}

.contact-info h2, .contact-form h2 {
    font-size: 1.5rem;
    color: #3498db;
    margin-bottom: 1rem;
}

.contact-info p {
    margin-bottom: 0.5rem;
}

.contact-info i {
    margin-left: 0.5rem;
    color: #3498db;
}

.social-media {
    margin-top: 1rem;
}

.social-media a {
    font-size: 1.5rem;
    color: #3498db;
    margin-left: 1rem;
    transition: color 0.3s ease;
}

.social-media a:hover {
    color: #2980b9;
}

.form-group {
    margin-bottom: 1rem;
}

label {
    display: block;
    margin-bottom: 0.5rem;
    color: #2c3e50;
}

input, textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
    font-size: 1rem;
}

textarea {
    height: 150px;
}

.submit-btn {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #2980b9;
}
</style>