<template>
  <div class="chatbot-container">
    <div class="chatbot-toggle" @click="toggleChat">
      <img src="chat-icon.png" alt="Chat" />
    </div>
    <transition name="fade">
      <div v-if="isChatOpen" class="chatbot">
        <div class="chat-header">
          <h3>Chat with us</h3>
          <button @click="toggleChat" class="close-btn">&times;</button>
        </div>
        <div class="chat-window">
          <div v-for="message in messages" :key="message.id" class="message">
            <div :class="{'bot-message': message.isBot, 'user-message': !message.isBot}">
              {{ message.text }}
            </div>
          </div>
          <div v-if="isLoading" class="loading-indicator">...</div>
        </div>
        <input v-model="userInput" @keyup.enter="debouncedSendMessage" placeholder="Type your message here..." />
      </div>
    </transition>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  data() {
    return {
      messages: [],
      userInput: '',
      isChatOpen: false,
      isLoading: false,
    };
  },
  methods: {
    toggleChat() {
      this.isChatOpen = !this.isChatOpen;
    },
    debouncedSendMessage: debounce(function() {
      this.sendMessage();
    }, 300),
    async sendMessage() {
      if (this.userInput.trim() === '') return;

      // Add user message to the chat
      this.messages.push({ id: Date.now(), text: this.userInput, isBot: false });

      // Clear input field
      const message = this.userInput;
      this.userInput = '';
      this.isLoading = true;

      try {
        // Send the message to the backend or Zapier
        const response = await this.sendToBackendOrZapier(message);

        // Add bot response to the chat
        this.messages.push({ id: Date.now(), text: response || 'No response from server.', isBot: true });
      } catch (error) {
        console.error('Error sending message:', error);
        this.messages.push({ id: Date.now(), text: 'Sorry, something went wrong.', isBot: true });
      } finally {
        this.isLoading = false;
      }
    },
    async sendToBackendOrZapier(message) {
      // Integrate with your backend or Zapier Webhook
      try {
        const response = await fetch('YOUR_ZAPIER_WEBHOOK_URL', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ message }),
        });
        const data = await response.json();
        return data.reply;
      } catch (error) {
        console.error('Error sending message:', error);
        return null;
      }
    },
  },
};
</script>

<style scoped>
.chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.chatbot-toggle {
  background-color: #0084ff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatbot-toggle img {
  width: 24px;
  height: 24px;
}

.chatbot {
  width: 300px;
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 10px;
  animation: slide-up 0.3s ease-out;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.chat-header h3 {
  margin: 0;
  font-size: 16px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.chat-window {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  max-height: 400px;
}

.message {
  padding: 5px;
  margin-bottom: 5px;
}

.bot-message {
  background-color: #e0e0e0;
}

.user-message {
  background-color: #0084ff;
  color: white;
  text-align: right;
}

input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 4px;
}

@keyframes slide-up {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.loading-indicator {
  text-align: center;
  color: #999;
  margin: 10px 0;
}
</style>
