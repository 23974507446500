<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="50" viewBox="0 0 100 50">
        <rect width="100" height="50" fill="#eee" />
        <text x="50" y="25" font-family="Arial" font-size="12" fill="#333" text-anchor="middle" dy=".3em">Client
            Logo</text>
    </svg>
</template>

<script>
export default {
    name: 'ClientLogo'
}
</script>