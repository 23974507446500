<template>
  <div class="home">
    <section class="hero">
      <img src="@/assets/Designer (20).jpeg" alt="صورة البطل" class="hero-image" loading="lazy" />
      <div class="hero-content">
        <h1>ارتقِ بحضورك الرقمي</h1>
        <p>نحن نصمم مواقع ويب مذهلة تحقق نتائج رائعة لأعمالك</p>
        <div class="cta-buttons">
          <router-link to="/services" class="btn btn-primary">تعرف على المزيد</router-link>
          <router-link to="/booking" class="btn btn-secondary">احجز الآن</router-link>
        </div>
      </div>
    </section>

    <!-- Booking Preview Section -->
    <section class="booking-preview text-center">
      <h2>احجز استشارتك الحصرية</h2>
      <p>استمتع بجلسة تحويلية لمدة 45 دقيقة مع مستشارينا الخبراء. الأماكن محدودة.</p>
      <v-calendar
        :attributes="attributes"
        :min-date="new Date()"
        :max-date="maxDate"
        @dayclick="onDayClick"
        class="mb-8 shadow-lg rounded-lg overflow-hidden mx-auto"
        :theme-styles="calendarTheme"
      />
      <div v-if="selectedDate" class="mt-4">
        <p>التاريخ المختار: {{ formattedDate }}</p>
        <router-link to="/booking" class="btn btn-primary">أكمل الحجز</router-link>
      </div>
    </section>

    <section class="about text-center">
      <h2>معلومات عنا</h2>
      <p>نحن فريق من مطوري ومصممي الويب المتحمسين المكرسين لإنشاء تجارب رقمية استثنائية. مهمتنا هي مساعدة الشركات على الازدهار في العالم الرقمي من خلال حلول مبتكرة وموجهة نحو المستخدم.</p>
      <router-link to="/about" class="btn btn-outline">تعرف علينا أكثر</router-link>
    </section>

    <section class="services-overview text-center">
      <h2>خدماتنا</h2>
      <div class="services-grid">
        <div v-for="(service, index) in services" :key="index" class="service-item" :style="{ animationDelay: `${index * 0.2}s` }">
          <component :is="service.icon" class="service-icon" />
          <h3>{{ service.title }}</h3>
          <p>{{ service.description }}</p>
        </div>
      </div>
      <router-link to="/services" class="btn btn-outline">عرض جميع الخدمات</router-link>
    </section>

    <section class="social-proof text-center">
      <h2>موثوق به من قبل قادة الصناعة</h2>
      <div class="client-logos">
        <img v-for="(logo, index) in clientLogos" 
             :key="index" 
             :src="logo" 
             alt="شعار العميل" 
             loading="lazy" 
             @load="onLogoLoad" />
      </div>
      <div v-if="!allLogosLoaded" class="loading-spinner"></div>
      <div class="testimonials">
        <blockquote>
          "العمل مع هذا الفريق كان تغييرًا جذريًا لحضورنا على الإنترنت. لقد قدموا موقعًا يفوق توقعاتنا وزاد من تحويلاتنا بشكل كبير."
          <cite>- جون دو، الرئيس التنفيذي لشركة XYZ</cite>
        </blockquote>
      </div>
      <div class="case-study-preview">
        <h3>دراسة حالة مميزة: إعادة تصميم موقع ABC Corp</h3>
        <p>تعرف على كيفية مساعدتنا لشركة ABC Corp في زيادة مبيعاتها عبر الإنترنت بنسبة 200٪ من خلال إعادة تصميم الموقع واستراتيجية التحسين.</p>
        <router-link to="/portfolio/abc-corp" class="btn btn-outline">اقرأ دراسة الحالة</router-link>
      </div>
    </section>
  </div>
</template>

<script>
import { Calendar } from 'v-calendar';
import ClientLogo from '@/components/icons/ClientLogo.vue'
import WebDesignIcon from '@/components/icons/WebDesignIcon.vue'
import WebDevelopmentIcon from '@/components/icons/WebDevelopmentIcon.vue'
import SeoIcon from '@/components/icons/SeoIcon.vue'
import image1 from '@/assets/image_1024.png'
import image2 from '@/assets/images (1).png'
import image3 from '@/assets/images.png'

export default {
  name: 'Home',
  components: {
    VCalendar: Calendar,
    ClientLogo,
    WebDesignIcon,
    WebDevelopmentIcon,
    SeoIcon,
  },
  data() {
    return {
      clientLogos: [image1, image2, image3],
      clientLogosLoaded: false,
      services: [
        {
          icon: WebDesignIcon,
          title: 'تصميم الويب',
          description: 'إنشاء مواقع ويب مذهلة ومتجاوبة تجذب جمهورك.',
        },
        {
          icon: WebDevelopmentIcon,
          title: 'تطوير الويب',
          description: 'بناء تطبيقات ويب قوية وقابلة للتطوير تلبي احتياجاتك.',
        },
        {
          icon: SeoIcon,
          title: 'تحسين محركات البحث',
          description: 'تحسين رؤيتك على الإنترنت وجذب المزيد من العملاء المحتملين المؤهلين.',
        },
      ],
      loadedLogosCount: 0,
      selectedDate: null,
      maxDate: new Date(new Date().setMonth(new Date().getMonth() + 2)),
      attributes: [
        {
          highlight: {
            color: 'blue',
            fillMode: 'light',
          },
          dates: new Date(),
        },
      ],
      calendarTheme: {
        wrapper: { backgroundColor: '#f8fafc', borderRadius: '0.5rem', boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)' },
        dayContent: { fontWeight: '600', color: '#4a5568' },
        dayContentHover: { backgroundColor: '#edf2f7' },
      },
    }
  },
  computed: {
    allLogosLoaded() {
      return this.loadedLogosCount === this.clientLogos.length;
    },
    formattedDate() {
      return this.selectedDate ? this.selectedDate.toDateString() : '';
    }
  },
  methods: {
    onLogoLoad() {
      this.loadedLogosCount++;
    },
    onDayClick(day) {
      this.selectedDate = day.date;
    }
  },
}
</script>

<style scoped>
.hero {
  background-color: #f8f9fa;
  padding: 4rem 2rem;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-image {
  width: 100%;
  max-width: 600px;
  height: auto;
  object-fit: cover;
  margin-bottom: 2rem;
}

h1,
h2,
h3 {
  margin-bottom: 1rem;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: 0.25rem;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.btn::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.3s ease-out, height 0.3s ease-out;
}

.btn:hover::after {
  width: 300%;
  height: 300%;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
}

.btn-secondary:hover {
  background-color: #545b62;
}

.btn-outline {
  border: 2px solid #007bff;
  color: #007bff;
}

.btn-outline:hover {
  background-color: #007bff;
  color: white;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
  justify-items: center;
}

.service-icon {
  width: 64px;
  height: 64px;
  margin-bottom: 1rem;
}

.client-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.client-logos img {
  max-width: 100px;
  height: auto;
}

blockquote {
  font-style: italic;
  margin-bottom: 1rem;
}

cite {
  display: block;
  font-style: normal;
  margin-top: 0.5rem;
}

.case-study-preview {
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 0.5rem;
  margin-top: 2rem;
}

.hero-image {
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
}

.text-center {
  text-align: center;
}

.service-item {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (min-width: 768px) {
  .hero {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    padding: 4rem;
  }

  .hero-image {
    width: 50%;
    margin-bottom: 0;
    margin-left: 2rem;
  }

  .hero-content {
    width: 50%;
  }
}

.booking-preview {
  background-color: #f0f4f8;
  padding: 4rem 2rem;
  margin-top: 2rem;
  border-radius: 0.5rem;
}

.v-calendar {
  max-width: 600px;
  margin: 0 auto;
}
</style>