<template>
  <div class="booking-container">
    <h1 class="text-4xl font-bold mb-8 text-center text-gray-800">Book Your Exclusive Consultation</h1>
    
    <!-- Added content -->
    <div class="mb-12 text-center">
      <p class="text-xl text-gray-600 mb-4">Experience a transformative 45-minute session with our expert consultants.</p>
      <p class="text-lg text-gray-500">Limited slots available. Reserve your spot today.</p>
    </div>

    <div class="booking-content">
      <!-- Calendar Component -->
      <v-calendar
        :attributes="attributes"
        :min-date="new Date()"
        :max-date="maxDate"
        @dayclick="onDayClick"
        class="mb-8 shadow-lg rounded-lg overflow-hidden"
        :theme-styles="calendarTheme"
        aria-label="Calendar"
      />

      <!-- Selected Date and Time Slots -->
      <div v-if="selectedDate" class="mt-8">
        <h2 class="text-2xl font-semibold mb-6 text-gray-800">Available time slots for {{ formattedDate }}</h2>
        <div class="grid grid-cols-3 gap-6 mb-8">
          <button
            v-for="slot in timeSlots"
            :key="slot"
            @click="selectTimeSlot(slot)"
            :class="['time-slot', { 'selected': selectedTimeSlot === slot }]"
            :disabled="isSlotDisabled(slot)"
            :aria-label="`Select time slot ${slot}`"
            :aria-pressed="selectedTimeSlot === slot"
          >
            {{ slot }}
          </button>
        </div>

        <!-- Booking Form Component -->
        <BookingForm :selectedDate="selectedDate" :timeSlots="timeSlots" v-if="selectedTimeSlot" />
      </div>
      <div v-if="error" class="error-message text-red-500 mt-4">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import { Calendar } from 'v-calendar';
import BookingForm from '@/components/BookingForm.vue';

export default {
  name: 'Booking',
  components: {
    VCalendar: Calendar,
    BookingForm
  },
  data() {
    return {
      selectedDate: null,
      selectedTimeSlot: null,
      maxDate: new Date(new Date().setMonth(new Date().getMonth() + 2)),
      timeSlots: ['09:00', '10:00', '11:00', '13:00', '14:00', '15:00'],
      attributes: [
        {
          highlight: {
            color: 'blue',
            fillMode: 'light',
          },
          dates: new Date(),
        },
      ],
      error: null,
      calendarTheme: {
        wrapper: { backgroundColor: '#f8fafc', borderRadius: '0.5rem', boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)' },
        dayContent: { fontWeight: '600', color: '#4a5568' },
        dayContentHover: { backgroundColor: '#edf2f7' },
      },
    };
  },
  computed: {
    formattedDate() {
      return this.selectedDate ? this.selectedDate.toDateString() : '';
    },
    disabledTimeSlots() {
      const now = new Date();
      return this.timeSlots.reduce((acc, slot) => {
        const [hours, minutes] = slot.split(':').map(Number);
        const selectedTime = new Date(this.selectedDate);
        selectedTime.setHours(hours, minutes);
        acc[slot] = selectedTime < now;
        return acc;
      }, {});
    }
  },
  methods: {
    onDayClick(day) {
      try {
        this.selectedDate = day.date;
        this.selectedTimeSlot = null;
        this.error = null;
      } catch (err) {
        this.error = "An error occurred while selecting the date. Please try again.";
        console.error(err);
      }
    },
    selectTimeSlot(slot) {
      this.selectedTimeSlot = slot;
    },
    isSlotDisabled(slot) {
      return this.disabledTimeSlots[slot];
    }
  }
};
</script>

<style scoped>
.booking-container {
  @apply max-w-5xl mx-auto px-6 py-12;
}

.booking-content {
  @apply bg-white shadow-2xl rounded-2xl p-8;
}

.time-slot {
  @apply bg-gray-100 hover:bg-gray-200 py-3 px-6 rounded-lg transition duration-300 text-gray-700 font-semibold;
}

.time-slot.selected {
  @apply bg-indigo-600 text-white hover:bg-indigo-700;
}

.time-slot:disabled {
  @apply bg-gray-300 text-gray-500 cursor-not-allowed;
}

@media (max-width: 640px) {
  .grid {
    @apply grid-cols-2;
  }
}

@media (max-width: 480px) {
  .grid {
    @apply grid-cols-1;
  }
}
</style>
