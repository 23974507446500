<template>
    <div class="blog">
        <h1>Our Blog</h1>
    </div>
</template>

<script>
export default {
    name: 'Blog'
}
</script>