<template>
  <div class="booking-form">
    <h2 id="availableSlots" class="text-2xl font-semibold mb-6 text-gray-800">Secure Your Exclusive Time Slot</h2>

    <!-- Time Slots -->
    <div v-if="loadingSlots" class="mb-8 text-center" aria-live="polite">
      <p class="text-gray-600">Loading premium time slots...</p>
    </div>
    <div v-else class="grid grid-cols-3 gap-6 mb-8" role="group" aria-labelledby="availableSlots">
      <button
        v-for="slot in timeSlots"
        :key="slot"
        @click="selectTimeSlot(slot)"
        :class="['time-slot', { 'selected': selectedTimeSlot === slot }]"
        :disabled="isSlotDisabled(slot)"
        :aria-pressed="selectedTimeSlot === slot"
      >
        {{ slot }}
      </button>
    </div>

    <!-- Booking Form -->
    <form @submit.prevent="submitBooking" v-if="selectedTimeSlot" class="space-y-6">
      <div>
        <label for="name" class="block mb-2 text-sm font-medium text-gray-700">Full Name</label>
        <input type="text" id="name" v-model.trim="name" required class="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500" :class="{ 'border-red-500': v$.name.$error }">
        <p v-if="v$.name.$error" class="text-red-500 text-sm mt-1">{{ v$.name.$errors[0].$message }}</p>
      </div>
      <div>
        <label for="email" class="block mb-2 text-sm font-medium text-gray-700">Email Address</label>
        <input type="email" id="email" v-model.trim="email" required class="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500" :class="{ 'border-red-500': v$.email.$error }">
        <p v-if="v$.email.$error" class="text-red-500 text-sm mt-1">{{ v$.email.$errors[0].$message }}</p>
      </div>
      <div>
        <label for="phone" class="block mb-2 text-sm font-medium text-gray-700">Phone Number</label>
        <input type="tel" id="phone" v-model.trim="phone" class="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500" :class="{ 'border-red-500': v$.phone.$error }">
        <p v-if="v$.phone.$error" class="text-red-500 text-sm mt-1">{{ v$.phone.$errors[0].$message }}</p>
      </div>

      <!-- Submit Button with Loading State -->
      <button type="submit" class="w-full bg-indigo-600 text-white px-6 py-3 rounded-lg hover:bg-indigo-700 transition duration-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" :disabled="isSubmitting">
        <span v-if="isSubmitting">Securing Your Appointment...</span>
        <span v-else>Confirm Exclusive Consultation</span>
      </button>
    </form>

    <!-- Success Message -->
    <div v-if="successMessage" class="bg-green-50 border-l-4 border-green-400 p-4 mt-8" role="alert">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <p class="text-sm text-green-700">
            {{ successMessage }}
          </p>
        </div>
      </div>
    </div>

    <!-- Error Message -->
    <div v-if="errorMessage" class="bg-red-50 border-l-4 border-red-400 p-4 mt-8" role="alert">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <p class="text-sm text-red-700">
            {{ errorMessage }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'

export default {
  name: 'BookingForm',
  props: {
    selectedDate: {
      type: Date,
      required: true
    },
    timeSlots: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedTimeSlot: null,
      name: '',
      email: '',
      phone: '',
      isSubmitting: false,
      loadingSlots: true,
      errorMessage: '',
      successMessage: ''
    };
  },
  computed: {
    formattedDate() {
      return this.selectedDate.toDateString();
    }
  },
  watch: {
    selectedDate(newDate) {
      this.fetchAvailableSlots(newDate); // Fetch slots when date changes
    }
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      name: { required: helpers.withMessage('Name is required', required) },
      email: { 
        required: helpers.withMessage('Email is required', required),
        email: helpers.withMessage('Please enter a valid email address', email)
      },
      phone: { required: helpers.withMessage('Phone number is required', required) }
    }
  },
  methods: {
    async fetchAvailableSlots(date) {
      this.loadingSlots = true;
      this.errorMessage = '';
      try {
        // Simulate an API call to fetch available slots
        const response = await fetch(`/api/getAvailableSlots?date=${date.toISOString()}`);
        const data = await response.json();
        this.timeSlots = data.timeSlots;
      } catch (error) {
        this.errorMessage = 'Failed to load available slots. Please try again.';
      } finally {
        this.loadingSlots = false;
      }
    },
    selectTimeSlot(slot) {
      this.selectedTimeSlot = slot;
    },
    async submitBooking() {
      this.isSubmitting = true;
      this.errorMessage = '';
      this.successMessage = '';
      
      const isFormValid = await this.v$.$validate()
      if (!isFormValid) {
        this.isSubmitting = false;
        return;
      }

      try {
        const bookingData = {
          date: this.selectedDate,
          time: this.selectedTimeSlot,
          name: this.name,
          email: this.email,
          phone: this.phone
        };
        // Simulate an API call to submit the booking
        const response = await fetch('/api/submitBooking', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(bookingData)
        });
        if (!response.ok) throw new Error('Booking failed. Please try again.');
        this.successMessage = 'Your consultation has been booked successfully!';
        this.resetForm();
      } catch (error) {
        this.errorMessage = error.message;
      } finally {
        this.isSubmitting = false;
      }
    },
    resetForm() {
      this.selectedTimeSlot = null;
      this.name = '';
      this.email = '';
      this.phone = '';
      this.v$.$reset();
    },
    isSlotDisabled(slot) {
      // Example logic to determine if a time slot should be disabled
      return false; // Customize this as needed
    }
  },
  mounted() {
    this.fetchAvailableSlots(this.selectedDate);
  }
};
</script>

<style scoped>
.time-slot {
  @apply bg-gray-100 hover:bg-gray-200 py-3 px-6 rounded-lg transition duration-300 text-gray-700 font-semibold;
}
.time-slot.selected {
  @apply bg-indigo-600 text-white hover:bg-indigo-700;
}
.time-slot:disabled {
  @apply bg-gray-300 text-gray-500 cursor-not-allowed;
}
</style>
