<template>
    <div id="app" class="glass">
        <header class="sticky-header glass">
            <nav>
                <router-link to="/" class="logo">
                    <img src="@/assets/Logo.png" alt="Logo" />
                </router-link>
                <ul class="nav-links">
                    <li><router-link to="/" exact>Home</router-link></li>
                    <li><router-link to="/about">About</router-link></li>
                    <li><router-link to="/services">Services</router-link></li>
                    <li><router-link to="/portfolio">Portfolio</router-link></li>
                    <li><router-link to="/blog">Blog</router-link></li>
                    <li><router-link to="/contact">Contact</router-link></li>
                </ul>
            </nav>
        </header>
        <main>
            <router-view></router-view>
            <!-- Add the ChatBot component here -->
            <ChatBot />
        </main>
        <Footer />
    </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import ChatBot from '@/components/ChatBot.vue' // Import the ChatBot component

export default {
    name: 'App',
    components: {
        Footer,
        ChatBot // Register the ChatBot component
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            const header = document.querySelector('.sticky-header');
            if (window.scrollY > 50) {
                header.classList.add('scrolled');
            } else {
                header.classList.remove('scrolled');
            }
        }
    }
}
</script>

<style>
#app {
    font-family: Arial, sans-serif;
}

.sticky-header {
    position: sticky;
    top: 0;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3)); /* Gradient background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
    transition: background 0.3s ease; /* Smooth transition for background */
}

.logo img {
    height: 40px;
    width: auto;
    transition: transform 0.3s ease; /* Smooth transition for transform */
}

.logo img:hover {
    transform: scale(1.1); /* Scale up on hover */
}

.nav-links a {
    text-decoration: none;
    color: #333;
    font-weight: 500;
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for color and transform */
}

.nav-links a:hover,
.nav-links a.router-link-active {
    color: #007bff;
    transform: translateY(-2px); /* Slightly move up on hover */
}

main {
    padding: 2rem;
    transition: padding 0.3s ease; /* Smooth transition for padding */
}

main:hover {
    padding: 2.5rem; /* Increase padding on hover */
}
</style>