var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"services",attrs:{"dir":"rtl"}},[_c('h1',[_vm._v("خدماتنا")]),_c('p',{staticClass:"intro"},[_vm._v("نحن متخصصون في حلول تطوير الويب والتطبيقات المصممة خصيصًا للمنطقة العربية، ونقدم مجموعة من الخدمات لتلبية احتياجاتك الرقمية.")]),_c('div',{staticClass:"service-list"},[_c('div',{staticClass:"service-item"},[_c('font-awesome-icon',{staticClass:"service-icon",attrs:{"icon":['fas', 'laptop-code'],"aria-label":"تطوير الويب"}}),_vm._m(0)],1),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)]),_c('div',{staticClass:"cta-section"},[_c('h2',[_vm._v("هل أنت مستعد لرفع مستوى وجودك الرقمي في السوق العربية؟")]),_c('router-link',{staticClass:"cta-button",attrs:{"to":"/contact"}},[_vm._v("تواصل معنا")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-content"},[_c('h2',[_vm._v("تطوير الويب")]),_c('p',[_vm._v("مواقع ويب مخصصة ومتجاوبة محسنة للغتين العربية والإنجليزية، تضمن تجربة مستخدم سلسة عبر جميع الأجهزة.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-item"},[_c('i',{staticClass:"fas fa-mobile-alt service-icon",attrs:{"aria-label":"تطوير تطبيقات الجوال"}}),_c('div',{staticClass:"service-content"},[_c('h2',[_vm._v("تطوير تطبيقات الجوال")]),_c('p',[_vm._v("تطبيقات جوال أصلية ومتعددة المنصات مصممة لتلبية الاحتياجات الفريدة للسوق العربية، مع دعم لواجهة المستخدم العربية.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-item"},[_c('i',{staticClass:"fas fa-shopping-cart service-icon",attrs:{"aria-label":"حلول التجارة الإلكترونية"}}),_c('div',{staticClass:"service-content"},[_c('h2',[_vm._v("حلول التجارة الإلكترونية")]),_c('p',[_vm._v("متاجر إلكترونية قوية مع بوابات دفع متكاملة شائعة في العالم العربي، ودعم متعدد اللغات، وميزات خاصة بالمنطقة.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-item"},[_c('i',{staticClass:"fas fa-users service-icon",attrs:{"aria-label":"استشارات تكنولوجيا المعلومات"}}),_c('div',{staticClass:"service-content"},[_c('h2',[_vm._v("استشارات تكنولوجيا المعلومات")]),_c('p',[_vm._v("نصائح خبيرة حول التحول الرقمي، واختيار التقنيات المناسبة، واستراتيجية تكنولوجيا المعلومات المصممة للشركات العاملة في المنطقة العربية.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-item"},[_c('i',{staticClass:"fas fa-tasks service-icon",attrs:{"aria-label":"إدارة المشاريع"}}),_c('div',{staticClass:"service-content"},[_c('h2',[_vm._v("إدارة المشاريع")]),_c('p',[_vm._v("إشراف فعال على مشاريعك الرقمية من البداية إلى الإطلاق، مع ضمان التسليم في الوقت المحدد والالتزام بمعايير الامتثال الإقليمية.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-item"},[_c('i',{staticClass:"fas fa-bolt service-icon",attrs:{"aria-label":"مشاريع سريعة التنفيذ"}}),_c('div',{staticClass:"service-content"},[_c('h2',[_vm._v("مشاريع سريعة التنفيذ")]),_c('p',[_vm._v("تطوير وإطلاق سريع للمنتجات الأولية القابلة للتطبيق، وصفحات الهبوط، والتطبيقات صغيرة الحجم لتلبية احتياجات العمل العاجلة.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-item"},[_c('i',{staticClass:"fas fa-search-plus service-icon",attrs:{"aria-label":"تحسين محركات البحث والتسويق الرقمي باللغة العربية"}}),_c('div',{staticClass:"service-content"},[_c('h2',[_vm._v("تحسين محركات البحث والتسويق الرقمي باللغة العربية")]),_c('p',[_vm._v("تحسين وجودك الرقمي لمحركات البحث العربية وتنفيذ استراتيجيات التسويق الرقمي الخاصة بالمنطقة.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-item"},[_c('i',{staticClass:"fas fa-pencil-ruler service-icon",attrs:{"aria-label":"تصميم واجهة المستخدم وتجربة المستخدم للواجهات العربية"}}),_c('div',{staticClass:"service-content"},[_c('h2',[_vm._v("تصميم واجهة المستخدم وتجربة المستخدم للواجهات العربية")]),_c('p',[_vm._v("إنشاء واجهات مستخدم بديهية ومناسبة ثقافيًا، مع التركيز على الطباعة العربية والتخطيط وتجربة المستخدم.")])])])
}]

export { render, staticRenderFns }