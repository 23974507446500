<template>
    <footer class="site-footer">
        <div class="footer-content">
            <div class="quick-links">
                <h3>Quick Links</h3>
                <ul>
                    <li><router-link to="/">Home</router-link></li>
                    <li><router-link to="/about">About</router-link></li>
                    <li><router-link to="/services">Services</router-link></li>
                    <li><router-link to="/portfolio">Portfolio</router-link></li>
                    <li><router-link to="/blog">Blog</router-link></li>
                    <li><router-link to="/contact">Contact</router-link></li>
                    <li><router-link to="/booking">Booking</router-link></li> <!-- Added Booking link -->
                </ul>
            </div>
            <div class="social-media">
                <h3>Follow Us</h3>
                <ul>
                    <li><a href="https://twitter.com/yourcompany" target="_blank" rel="noopener noreferrer">Twitter</a>
                    </li>
                    <li><a href="https://facebook.com/yourcompany" target="_blank"
                            rel="noopener noreferrer">Facebook</a></li>
                    <li><a href="https://linkedin.com/company/yourcompany" target="_blank"
                            rel="noopener noreferrer">LinkedIn</a></li>
                </ul>
            </div>
            <div class="contact-info">
                <h3>Contact Us</h3>
                <p>Email: <a href="mailto:info@yourcompany.com">info@yourcompany.com</a></p>
            </div>
        </div>
        <div class="copyright">
            <p>&copy; {{ new Date().getFullYear() }} Your Company Name. All rights reserved.</p>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped>
.site-footer {
    background-color: #333;
    color: #fff;
    padding: 2rem 0;
}

.footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
}

.quick-links,
.social-media,
.contact-info {
    margin-bottom: 1rem;
}

h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 0.5rem;
}

a {
    color: #fff;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.copyright {
    text-align: center;
    margin-top: 2rem;
    font-size: 0.9rem;
}
</style>