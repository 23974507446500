<template>
    <div class="about">
      <h1>About Us</h1>
      <p class="intro">We are a team of visionary experts united by a shared passion for innovation. With diverse expertise spanning data science, network engineering, 3D animation, app development, and VR programming, we collaborate to create transformative digital solutions. Our mission is to empower businesses to thrive in a rapidly evolving landscape by delivering cutting-edge technologies that drive growth, enhance efficiency, and foster innovation.</p>
  
      <div class="team-section">
        <h2>Meet the Founders</h2>
        <div class="team-member">
          <img src="@/assets/motasem.jpg" alt="Motasem" class="team-photo" />
          <div>
            <h3>Motasem</h3>
            <p>PhD in Data Science</p>
            <p>Motasem’s journey through data science began in academia and has since led him to become a pioneer in leveraging data to drive actionable insights. With a PhD and a portfolio of advanced machine learning models, he has solved some of the industry’s most complex problems. Motasem is dedicated to unlocking new opportunities for businesses through data-driven innovation.</p>
          </div>
        </div>
        <div class="team-member">
          <img src="@/assets/Yousif.jpg" alt="Yousif" class="team-photo" />
          <div>
            <h3>Yousif</h3>
            <p>Cybersecurity and Cloud Architecture Expert</p>
            <p>Yousif is the guardian of digital assets, specializing in cybersecurity and cloud architecture. His deep understanding of secure, scalable cloud solutions has helped numerous organizations protect their data while optimizing their cloud infrastructure. Yousif is committed to ensuring that businesses can operate seamlessly and securely in the cloud.</p>
          </div>
        </div>
        <div class="team-member">
          <img src="@/assets/omar.jpg" alt="Omar" class="team-photo" />
          <div>
            <h3>Omar Younis</h3>
            <p>Senior Animator and 3D Artist</p>
            <p>Omar brings imagination to life with his stunning animations and 3D creations. His technical expertise and artistic vision have captivated audiences across industries, pushing the boundaries of what’s possible in digital art. Omar is passionate about helping businesses tell their stories through visually compelling content.</p>
          </div>
        </div>
        <div class="team-member">
          <img src="@/assets/Gonzalo.jpg" alt="Gonzalo" class="team-photo" />
          <div>
            <h3>Gonzalo Vázquez</h3>
            <p>App Development and VR Specialist</p>
            <p>Gonzalo is a dual force in the digital world, combining his expertise in app development with cutting-edge VR technology. His deep understanding of both fields allows him to create seamless, immersive experiences that transform how users interact with digital environments. Whether developing a high-performance app or crafting a groundbreaking VR experience, Gonzalo’s work helps businesses innovate and captivate their audiences. He is dedicated to pushing the boundaries of what's possible in both app development and virtual reality.</p>
          </div>
        </div>
      </div>
  
      <div class="mission-section">
        <h2>Our Mission</h2>
        <p>We exist to empower businesses with innovative digital solutions that harness the power of data and technology. Our mission is to deliver tools and strategies that drive growth, enhance efficiency, and keep organizations ahead of the curve.</p>
      </div>
  
      <div class="vision-section">
        <h2>Our Vision</h2>
        <p>We envision a world where advanced technologies are within reach for businesses of all sizes, enabling them to achieve their goals with ease. Our goal is to be the trusted partner that companies turn to for cutting-edge solutions in data science, network engineering, 3D animation, app development, and VR programming, driving innovation and sustainable growth.</p>
      </div>
  
      <div class="cta-section">
        <h2>Ready to Transform Your Business?</h2>
        <p>Let's discuss how our expertise can help you achieve your goals.</p>
        <button @click="contactUs" class="cta-button">Contact Us</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'About',
    methods: {
      contactUs() {
        // Implement contact functionality (e.g., navigate to contact page or open modal)
        console.log('Contact button clicked');
      }
    }
  }
  </script>
  
  <style scoped>
  .about {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  h1, h2 {
    color: #333;
    margin-bottom: 1rem;
  }
  
  .intro, p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 1rem;
    line-height: 1.6;
  }
  
  .team-section {
    margin-top: 2rem;
  }
  
  .team-member {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;
  }
  
  .team-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-right: 1.5rem;
    object-fit: cover;
  }
  
  .mission-section, .vision-section, .cta-section {
    margin-top: 2rem;
  }
  
  .cta-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1.1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: #0056b3;
  }
  </style>
